import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-collapse';
import { Link } from 'gatsby';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import isRelativeUri from '../../../utils/isRelativeUri';
import Typography from '../../Typography';
import Icon from '../../Icon';

const SubItems = ({ item, items, openMenuItem, handleClickMenuItem, handleClickAngle, docs, location }) => (
  <div className="d-md-none">
    <Collapse isOpened={openMenuItem?.includes(item?.slug)}>
      <ul className="list-unstyled px-15">
        {items?.map(({ node: subItem }) => {
          const isRelative = isRelativeUri(subItem?.href);
          const Component = isRelative ? Link : 'a';
          const href = `/${subItem.locale !== 'en' ? 'nl/' : ''}${subItem.slug.replace(/(^en\/)/gi, '')}`;

          const subItems = docs.filter(
            ({ node }) => node?.strapiParent && node?.strapiParent?.id === subItem?.strapiId,
          );

          return (
            <li
              className={clsx('nav-item', {
                active: location?.pathname?.startsWith(
                  `/${subItem.locale !== 'en' ? 'nl/' : ''}${subItem.slug.replace(/(^en\/)/gi, '')}`,
                ),
              })}
              key={subItem.title}
            >
              <div className="d-flex align-items-center">
                <Typography
                  variant="body"
                  component={Component}
                  to={href}
                  className="nav-link flex-fill py-lg-12"
                  title={subItem.title}
                  onClick={handleClickMenuItem}
                >
                  {subItem.title}
                </Typography>
                {subItems?.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-link text-gray-af-110 d-lg-none"
                    onClick={() => handleClickAngle(subItem)}
                    title={`open ${subItem.title}`}
                  >
                    <Icon
                      icon={faAngleDown}
                      className="d-md-none ml-auto"
                      rotation={openMenuItem?.includes(subItem.slug) ? 180 : 0}
                    />
                  </button>
                )}
              </div>
              {subItems.length > 0 && (
                <SubItems
                  openMenuItem={openMenuItem}
                  handleClickMenuItem={handleClickMenuItem}
                  handleClickAngle={handleClickAngle}
                  item={subItem}
                  items={subItems}
                  docs={docs}
                  location={location}
                />
              )}
            </li>
          );
        })}
      </ul>
    </Collapse>
  </div>
);

SubItems.propTypes = {
  docs: PropTypes.array,
  handleClickAngle: PropTypes.func,
  handleClickMenuItem: PropTypes.func,
  item: PropTypes.object,
  items: PropTypes.array,
  openMenuItem: PropTypes.string,
  location: PropTypes.object,
};

SubItems.defaultProps = {
  docs: [],
  handleClickAngle: undefined,
  handleClickMenuItem: undefined,
  item: {},
  items: [],
  openMenuItem: null,
  location: {},
};

export default SubItems;
