import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Toc from 'react-toc';
import * as commonmark from 'commonmark';
import { FormattedMessage } from 'react-intl';
import getSchemaBreadcrumbs from '../../utils/getSchemaBreadcrumbs';
import Grid from '../../components/Grid';
import Typography from '../../components/Typography';
import DocsLayout from '../../components/DocsLayout/DocsLayout';
import Breadcrumbs from '../../components/Breadcrumbs';
import Divider from '../../components/Divider';
import DocsTextBlock from '../../components/blocks/DocsTextBlock';
import translations from './translations';
import DocsFooter from './components/DocsFooter';
import formatLocale from '../../utils/formatLocale';

const getSecondHeading = markdown => {
  const reader = new commonmark.Parser();
  const doc = reader.parse(markdown);
  const walker = doc.walker();
  let event;
  let node;
  let secondHeading = '';

  // eslint-disable-next-line no-cond-assign
  while ((event = walker?.next())) {
    node = event.node;
    if (event.entering && node.type === 'heading' && node.level === 2 && !secondHeading) {
      secondHeading = node?.firstChild.literal;
    }
  }

  return !!secondHeading;
};

const DocsPage = ({ data, location }) => {
  const { site, strapi } = data;
  const { doc: strapiDoc } = strapi;
  const { title, id, blocks, locale, slug, localizations } = strapiDoc;
  const hasH2Headings = getSecondHeading(blocks?.[0]?.contents?.[0]?.body ?? '');

  const formattedLocalizations = localizations.reduce(
    (acc, lang) => [
      ...acc,
      {
        ...lang,
        slug: `/${lang.locale !== 'en' ? 'nl/' : ''}${lang.slug.replace(/(^en\/)/gi, '')}`,
      },
    ],
    [],
  );

  return (
    <DocsLayout
      location={location}
      currentPageId={id}
      locale={locale}
      localizations={formattedLocalizations}
      slug={`/${locale !== 'en' ? 'nl/' : ''}${slug.replace(/(^en\/)/gi, '')}`}
    >
      <Helmet
        title={title}
        meta={[
          {
            name: strapiDoc?.seo_title,
            content: strapiDoc?.seo_description,
          },
        ]}
      >
        <html lang={formatLocale(locale)} className="docs" />
        <script type="application/ld+json">
          {`
            [
              ${getSchemaBreadcrumbs(location)}
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "${site?.siteMetadata?.siteName}",
                "logo": "${site?.siteMetadata?.logo}",
                "@id": "${site?.siteMetadata?.company?.kvk}",
                "url": "${site?.siteMetadata?.siteUrl}",
                "foundingDate": "${site?.siteMetadata?.company?.foundingDate}",
                "founders": ["${site?.siteMetadata?.company?.founders.join('", "')}"],
                "contactPoint": {
                  "@context": "http://schema.org",
                  "@type": "contactPoint",
                  "contactType": "customer service",
                  "telephone": "${site?.siteMetadata?.company?.phoneNumber}",
                  "email": "${site?.siteMetadata?.company?.email}"
                },
                "sameAs": ["${site?.siteMetadata?.twitter}", "${site?.siteMetadata?.facebook}"],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${site?.siteMetadata?.address?.streetAddress}",
                  "addressLocality": "${site?.siteMetadata?.address?.locality}",
                  "addressRegion": "${site?.siteMetadata?.address?.region}",
                  "postalCode": "${site?.siteMetadata?.address?.postalCode}",
                  "addressCountry": "${site?.siteMetadata?.address?.country}"
                }
              }
            ]
          `}
        </script>
      </Helmet>
      <Breadcrumbs location={location} locale={locale} filter={['Home']} className="mb-24 mt-8" />
      <div className="d-flex flex-row-reverse m-n15">
        {hasH2Headings && (
          <div className="docs-toc p-15">
            <div className="docs-toc-menu">
              <Typography variant="overline" className="mb-12 d-block">
                <FormattedMessage {...translations.tocTitle} />
              </Typography>
              <Toc
                className="toc"
                markdownText={blocks?.[0]?.contents?.[0]?.body ?? ''}
                highestHeadingLevel={2}
                lowestHeadingLevel={2}
              />
            </div>
          </div>
        )}
        <div className="docs-body p-15">
          <Typography variant="h2" component="h1" className="mb-24">
            {title}
          </Typography>
          {blocks?.map(({ title: blockTitle, type, contents, id: blockId }) => {
            switch (type) {
              case 'text':
                return <DocsTextBlock key={blockId} title={blockTitle} contents={contents} wrapperClasses="" />;
              default:
                return (
                  <Grid key={blockId} container className="py-40">
                    <Grid column xs={12}>
                      <Typography>block: {blockTitle}</Typography>
                      <Typography>blockType: {type}</Typography>
                      <Divider />
                    </Grid>
                  </Grid>
                );
            }
          })}
          <DocsFooter />
        </div>
      </div>
    </DocsLayout>
  );
};

DocsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

DocsPage.defaultProps = {
  data: {},
  location: undefined,
};

export default DocsPage;

export const docsQuery = graphql`
  query($id: ID!) {
    site {
      siteMetadata {
        logo
        siteUrl
        siteName
        twitter
        facebook
        company {
          foundingDate
          founders
          phoneNumber
          email
        }
        address {
          streetAddress
          locality
          region
          postalCode
          country
        }
      }
    }
    strapi {
      doc(id: $id) {
        title
        slug
        id
        seo_title
        seo_description
        locale
        localizations {
          locale
          slug
        }
        blocks {
          contents {
            body
          }
          id
          type
        }
      }
    }
  }
`;
