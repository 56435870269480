import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useKey } from 'react-use';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button';
import Icon from '../Icon';
import Typography from '../Typography';
import Logo from '../../images/logo.svg';
import './style.scss';
// import Input from '../Input';
import SubItems from './SubItems';
import translations from './translations';

const DocsHeader = ({ location, variant, title, items, docs, locale }) => {
  const intl = useIntl();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [openMenuItem, setOpenMenuItem] = useState(location.pathname.replace(/^\/+/, ''));

  const handleClickMenuItem = () => {
    setMenuIsOpen(false);
  };

  const handleClickAngle = item => {
    if (item.slug !== openMenuItem && openMenuItem?.includes(item.slug)) {
      setOpenMenuItem(openMenuItem?.split('/').slice(0, -2).join('/'));
    } else {
      setOpenMenuItem(item.slug !== openMenuItem ? item.slug : openMenuItem?.split('/').slice(0, -1).join('/'));
    }
  };

  useKey('Escape', () => setMenuIsOpen(false));

  return (
    <nav className={clsx(`navbar ${variant} navbar-light flex-column docs-navbar flex-md-row bg-white`)}>
      <div className="container-fluid py-4 py-lg-0">
        <div className="d-flex align-items-center py-16">
          <a className="text-center" href={`/${locale === 'en' ? '' : `${locale.split('-')[0]}/`}`} title={title}>
            <Logo height={32} className="logo-light" />
          </a>
          {/* <Input id="search" name="q" className="ml-32 mr-32 docs-search" disabled /> */}
          <Typography
            variant="body"
            component="a"
            href={locale === 'en' ? 'https://afosto.app/auth/login' : 'https://app.afosto.com/login'}
            className="btn btn-text align-items-end p-0 d-none ml-auto d-lg-flex text-primary"
            title={intl.formatMessage(translations.loginLabel)}
          >
            <FormattedMessage {...translations.loginLabel} />
          </Typography>
          <Button
            color="link"
            className={clsx('p-12 d-lg-none ml-auto', `text-black`)}
            type="button"
            onClick={() => setMenuIsOpen(true)}
          >
            <span className="sr-only">Open menu</span>
            <Icon icon={faBars} size="lg" className="text-gray-af-110" />
          </Button>
        </div>
      </div>
      <div className="container-fluid">
        <div className={clsx('navbar-drawer', { in: menuIsOpen })}>
          <div className="navbar-drawer-header">
            <Link className="text-center" to="/" title={title}>
              <Logo height={32} className="logo-light" />
            </Link>
            <Button color="link" className="text-gray-1000 navbar-drawer-close" onClick={() => setMenuIsOpen(false)}>
              <span className="sr-only">Sluit menu</span>
              <Icon icon={faTimes} fixedWidth size="lg" />
            </Button>
          </div>
          <ul className="navbar-nav flex-column flex-lg-row mx-n15">
            <li
              className={clsx('nav-item', {
                active: location?.pathname === `/${locale !== 'en' ? 'nl/' : ''}docs/`,
              })}
              key="/docs"
            >
              <div className="d-flex align-items-center">
                <Typography
                  variant="body"
                  component={Link}
                  to={`/${locale !== 'en' ? 'nl/' : ''}docs/`}
                  className="nav-link flex-fill py-lg-12"
                  title="home"
                  onClick={handleClickMenuItem}
                >
                  Home
                </Typography>
              </div>
            </li>
            {items.map(({ node: item }) => {
              const Component = item?.slug ? Link : 'button';
              const subItems = docs.filter(
                ({ node }) => node?.strapiParent && node?.strapiParent?.id === item?.strapiId,
              );
              return (
                <li
                  className={clsx('nav-item', {
                    active: location?.pathname.startsWith(
                      `/${item.locale !== 'en' ? 'nl/' : ''}${item.slug.replace(/(^en\/)/gi, '')}`,
                    ),
                  })}
                  key={item.slug}
                >
                  <div className="d-flex align-items-center">
                    <Typography
                      variant="body"
                      component={Component}
                      to={`/${item.locale !== 'en' ? 'nl/' : ''}${item.slug.replace(/(^en\/)/gi, '')}`}
                      className="nav-link flex-fill py-lg-12"
                      title={item.title}
                      onClick={handleClickMenuItem}
                    >
                      {item.title}
                    </Typography>
                    {subItems?.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-link text-gray-af-110 d-lg-none"
                        onClick={() => handleClickAngle(item)}
                        title={`open ${item.label}`}
                      >
                        <Icon
                          icon={faAngleDown}
                          className="d-md-none ml-auto"
                          rotation={openMenuItem?.includes(item.slug) ? 180 : 0}
                        />
                      </button>
                    )}
                  </div>
                  {subItems.length > 0 && (
                    <SubItems
                      openMenuItem={openMenuItem}
                      handleClickMenuItem={handleClickMenuItem}
                      handleClickAngle={handleClickAngle}
                      item={item}
                      items={subItems}
                      docs={docs}
                      location={location}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="navbar-drawer-backdrop" onClick={() => setMenuIsOpen(false)} aria-hidden />
      </div>
    </nav>
  );
};

DocsHeader.propTypes = {
  docs: PropTypes.array,
  location: PropTypes.object,
  locale: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'sticky-top', 'fixed-top', 'fixed-bottom']),
  items: PropTypes.array,
};

DocsHeader.defaultProps = {
  docs: [],
  location: undefined,
  locale: 'en',
  title: undefined,
  variant: 'sticky-top',
  items: [],
};

export default DocsHeader;
