import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './components/MenuItem';
import './style.scss';

const DocsMenu = ({ items, parent }) => {
  const subDocs = items.filter(({ node }) => node?.strapiParent && node?.strapiParent?.id === parent?.node?.strapiId);
  return (
    <aside className="docs-sidebar d-none d-md-block">
      <nav className="nav flex-column">
        {subDocs.map(({ node }) => (
          <MenuItem item={node} items={items} parent={parent} key={node.id} />
        ))}
      </nav>
    </aside>
  );
};

DocsMenu.propTypes = {
  items: PropTypes.array,
  parent: PropTypes.object,
};

DocsMenu.defaultProps = {
  items: [],
  parent: {},
};

export default DocsMenu;
