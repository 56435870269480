import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'gatsby';
import React from 'react';
import translations from './translations';

const DocsFooter = () => {
  const intl = useIntl();

  return (
    <div className="docs-footer">
      <FormattedMessage
        {...translations.moreQuestions}
        values={{
          link: (
            <Link to={intl.formatMessage(translations.moreQuestionsLinkUrl)}>
              <FormattedMessage {...translations.moreQuestionsLinkLabel} />
            </Link>
          ),
        }}
      />
    </div>
  );
};

export default DocsFooter;
