import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const Divider = ({ color, orientation, className }) => (
  <hr className={clsx(`divider text-${color}`, `divider-${orientation}`, className)} />
);

Divider.propTypes = {
  color: PropTypes.oneOf(['gray-300', 'af-gray-120']),
  className: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

Divider.defaultProps = {
  color: 'gray-300',
  className: undefined,
  orientation: 'horizontal',
};

export default Divider;
