import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import DocsMenu from '../DocsMenu';
import DocsHeader from '../DocsHeader';
import './style.scss';
import CookieConsent from '../CookieConsent';
import english from '../../translations/en.json';
import dutch from '../../translations/nl.json';
import formatLocale from '../../utils/formatLocale';

const messages = {
  en: english,
  'en-au': english,
  'en-bz': english,
  'en-ca': english,
  'en-ie': english,
  'en-jm': english,
  'en-nz': english,
  'en-za': english,
  'en-tt': english,
  'en-gb': english,
  'en-us': english,
  nl: dutch,
  'nl-be': dutch,
  'nl-nl': dutch,
  be: dutch,
};

const DocsLayout = ({ children, locale, localizations, slug }) => {
  const data = useStaticQuery(graphql`
    query DocsMenuQuery {
      allStrapiDoc {
        edges {
          node {
            title
            strapiId
            slug
            locale
            strapiParent {
              title
              id
              slug
            }
          }
        }
      }
    }
  `);

  const location = useLocation();
  const { allStrapiDoc } = data || {};
  const { edges } = allStrapiDoc || [];
  const localizedEdges = edges.filter(({ node }) => node.locale === locale);
  const rootDocs = localizedEdges.filter(({ node }) => node?.strapiParent === null);
  const [currentRootSlug] = rootDocs.filter(({ node }) =>
    location.pathname.includes(`/${node.locale !== 'en' ? 'nl/' : ''}${node.slug.replace(/(^en\/)/gi, '')}`),
  );
  const subDocs = localizedEdges.filter(
    ({ node }) => node?.strapiParent && node.strapiParent?.id === currentRootSlug?.node?.strapiId,
  );

  return (
    <IntlProvider locale={locale.toLowerCase()} messages={messages[locale.toLowerCase()]}>
      <Helmet>
        {localizations.length > 0 && (
          <link
            rel="alternate"
            href={
              slug === 'hp'
                ? process.env.GATSBY_BASE_URL
                : `${process.env.GATSBY_BASE_URL}${slug?.startsWith('/') ? '' : '/'}${slug ?? ''}`
            }
            hrefLang={formatLocale(locale)}
            key={locale}
          />
        )}
        {localizations.map(lang => (
          <link
            rel="alternate"
            href={
              lang.slug === 'hp'
                ? process.env.GATSBY_BASE_URL
                : `${process.env.GATSBY_BASE_URL}${lang.slug.startsWith('/') ? '' : '/'}${lang.slug}`
            }
            hrefLang={formatLocale(lang.locale)}
            key={lang.locale}
          />
        ))}
      </Helmet>
      <div className="docs-layout">
        <DocsHeader
          location={location}
          items={rootDocs}
          docs={localizedEdges}
          active={currentRootSlug}
          locale={locale}
        />
        <main className={clsx('docs-content', { 'no-sidebar': subDocs.length === 0 })}>
          {subDocs.length > 0 && <DocsMenu items={localizedEdges} parent={currentRootSlug} />}
          <div className="docs-content-inner">
            <div className="docs-content-scroll">{children}</div>
          </div>
        </main>
        <CookieConsent />
      </div>
    </IntlProvider>
  );
};

DocsLayout.propTypes = {
  children: PropTypes.any.isRequired,
  locale: PropTypes.string,
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  slug: PropTypes.string,
};

DocsLayout.defaultProps = {
  locale: 'en',
  localizations: [],
  slug: undefined,
};

export default DocsLayout;
