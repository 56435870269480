import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { useLocation } from '@reach/router';
import { Collapse } from 'react-collapse/lib/Collapse';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import Typography from '../../../Typography';
import Icon from '../../../Icon';

const MenuItem = ({ item, items }) => {
  const location = useLocation();
  const href = `/${item.locale !== 'en' ? 'nl/' : ''}${item.slug.replace(/(^en\/)/gi, '')}`;
  const childItems = items.filter(({ node }) => node?.strapiParent && node?.strapiParent?.id === item?.strapiId);

  return (
    <li className="nav-item">
      <Typography
        component={Link}
        to={href}
        className={clsx('nav-link', { active: location?.pathname?.startsWith(href) })}
      >
        <div className="d-flex">
          {childItems.length > 0 && (
            <Icon
              icon={faAngleRight}
              fixedWidth
              className="mt-4 ml-n16"
              rotation={location.pathname?.includes(href) ? 90 : 0}
            />
          )}
          {item?.title}
        </div>
      </Typography>
      {childItems.length > 0 && (
        <Collapse isOpened={location?.pathname?.startsWith(href)}>
          <ul className="list-unstyled">
            {childItems.map(({ node }) => (
              <MenuItem item={node} items={items} key={node.id} />
            ))}
          </ul>
        </Collapse>
      )}
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object,
  items: PropTypes.array,
};

MenuItem.defaultProps = {
  item: {},
  items: [],
};

export default MenuItem;
