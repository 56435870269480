import { defineMessages } from 'react-intl';

export default defineMessages({
  moreQuestions: {
    id: 'site.pages.docs.article.components.footer.moreQuestions.text',
    defaultMessage: 'More questions? {link}',
    description: 'more questions text in the footer of the docs page',
  },
  moreQuestionsLinkUrl: {
    id: 'site.pages.docs.article.components.footer.moreQuestions.url',
    defaultMessage: '/contact',
    description: 'more questions link url in the footer of the docs page',
  },
  moreQuestionsLinkLabel: {
    id: 'site.pages.docs.article.components.footer.moreQuestions.label',
    defaultMessage: 'Get in touch with us!',
    description: 'more questions link label in the footer of the docs page',
  },
});
