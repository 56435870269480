import React from 'react';
import PropTypes from 'prop-types';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import Typography from '../../Typography';
import Markdown from '../../Markdown';
import dasherize from '../../../utils/dasherize';
import Icon from '../../Icon';

const DocsTextBlock = ({ contents }) => {
  const [firstContent] = contents;
  const { body } = firstContent;
  return (
    <div className="docs-text">
      <Markdown
        components={{
          /* eslint-disable react/prop-types */
          paragraph: ({ children }) => <Typography className="mb-24">{children}</Typography>,
          h1: props => {
            const { children, level, node } = props;
            const headingLevel = `h${level}`;
            const text = node?.children?.[0]?.value ?? '';
            return (
              <Typography variant={headingLevel} id={`${dasherize(text)}`}>
                <span>
                  {children}
                  <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                    <Icon icon={faLink} />
                  </a>
                </span>
              </Typography>
            );
          },
          h2: props => {
            const { children, level, node } = props;
            const headingLevel = `h${level + 1}`;
            const text = node?.children?.[0]?.value ?? '';
            return (
              <Typography variant={headingLevel} component={`h${level}`} id={`${dasherize(text)}`}>
                <span>
                  {children}
                  <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                    <Icon icon={faLink} />
                  </a>
                </span>
              </Typography>
            );
          },
          h3: props => {
            const { children, level, node } = props;
            const headingLevel = `h${level + 1}`;
            const text = node?.children?.[0]?.value ?? '';
            return (
              <Typography variant={headingLevel} component={`h${level}`} id={`${dasherize(text)}`}>
                <span>
                  {children}
                  <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                    <Icon icon={faLink} />
                  </a>
                </span>
              </Typography>
            );
          },
          h4: props => {
            const { children, level, node } = props;
            const headingLevel = `h${level}`;
            const text = node?.children?.[0]?.value ?? '';
            return (
              <Typography variant={headingLevel} id={`${dasherize(text)}`}>
                <span>
                  {children}
                  <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                    <Icon icon={faLink} />
                  </a>
                </span>
              </Typography>
            );
          },
          h5: props => {
            const { children, level, node } = props;
            const headingLevel = `h${level}`;
            const text = node?.children?.[0]?.value ?? '';
            return (
              <Typography variant={headingLevel} id={`${dasherize(text)}`}>
                <span>
                  {children}
                  <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                    <Icon icon={faLink} />
                  </a>
                </span>
              </Typography>
            );
          },
          h6: props => {
            const { children, level, node } = props;
            const headingLevel = `h${level}`;
            const text = node?.children?.[0]?.value ?? '';
            return (
              <Typography variant={headingLevel} id={`${dasherize(text)}`}>
                <span>
                  {children}
                  <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                    <Icon icon={faLink} />
                  </a>
                </span>
              </Typography>
            );
          },
          listItem: ({ children }) => <Typography component="li">{children}</Typography>,
          /* eslint-enable react/prop-types */
        }}
      >
        {body}
      </Markdown>
    </div>
  );
};

DocsTextBlock.propTypes = {
  contents: PropTypes.array,
};

DocsTextBlock.defaultProps = {
  contents: [],
};

export default DocsTextBlock;
